import React, { Fragment, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import '../Induction/BodilyFluids.css';
import {Routes, Route, useNavigate, } from 'react-router-dom';
import moment from "moment";
import { getDefaultNormalizer } from "@testing-library/react";

import { CSVLink } from "react-csv";
import { FaStoreAltSlash } from 'react-icons/fa';
import sendEmail2 from '../Sendform/sendHandSform';
import PulseLoader from 'react-spinners/PulseLoader';

function BulkChillers() {
  const today = moment().format("YYYY-MM-DD");
  const [date, setDate] = useState(today);
  const [data, setData] = useState([]);
  const [forms, setForm] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [store, setStore] = useState([]);
  const [name, setName] = useState("");
  const [radio1, setRadio1] = useState("");
  const [radio2, setRadio2] = useState("");
  const [radio3, setRadio3] = useState("");
  const [radios, setRadios] = useState("");
  const [form_id, set_form_id] = useState("");
  const [formname, setFormname] = useState("");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const [question1, setQuestion1] = useState("I have watched and understood the details of the CO2 Refrigeration alarm and Lock-in Alarm Store Operating Procedure.");
  const [question2, setQuestion2] = useState("What action should you take if the CO2 alarm and blue flashing light is activated?");
  const [question3, setQuestion3] = useState("How do you make sure no-one enters the room until it is safe to do so?");
  const [answer1, setAnswer1] = useState("Yes");
  const [answer2, setAnswer2] = useState("All of the above.");
  const [answer3, setAnswer3] = useState("All of the above");
  const [question4, setQuestion4] = useState("What must you NOT do if you find someone who is unconscious?");
  const [answer4, setAnswer4] = useState("Go into the room");
  const [question5, setQuestion5] = useState("How do you know the Lock-in Alarm has been activated?");
  const [answer5, setAnswer5] = useState("A red light will be flashing, and an alarm will sound");
  const [question6, setQuestion6] = useState("What do you do if the alarm is activated?");
  const [answer6, setAnswer6] = useState("Immediately open the door");

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  
  const navigate = useNavigate();
  const navigateInduction = () => {
    navigate('/induction');
  };

  const getForms = async (id) => {
    var form_id = id;
    var form_array_number = id - 1;
    var jsonData2 = {};
    try {
      const response2 = await fetch(
        `${localStorage.getItem("url")}/api/forms/${id}`
      );
      jsonData2 = await response2.json();
        console.log(jsonData2)
    } catch (err) {
     
      console.log(err.message);
    }

    setForm(jsonData2);
    set_form_id(id);
    
    setFormname(jsonData2[0].name)
    
   
    
    

  };

  const getQuestions = async (id) =>{

    var form_id = id;
    var form_array_number = id - 1;
    var jsonData2 = {};
    try {
      const response2 = await fetch(
        `${localStorage.getItem("url")}/api/questions/${id}`
      );
      jsonData2 = await response2.json();
        console.log(jsonData2)
    } catch (err) {
     
      console.log(err.message);
    }

    jsonData2.forEach((entry) => {
      if(!questions.includes(entry.question_id)){
        console.log(entry);
        questions.push(entry.question_id)
      }
      
    })

  };

    const form_submit = async (e) =>{
      e.preventDefault();
      setActive(true);
    console.log(questions.toLocaleString());
      if(validateInputs() == true){
     
        try{
    
          const id = form_id;
          const user_id = localStorage.getItem("user_id")

          const body ={id,user_id,date}
          const response = await fetch(
          
            `${localStorage.getItem("url")}/api/completeForm`,
            {
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
          sendEmail2(formname,first_name,last_name,question1,answer1,question2,answer2,question3,answer3,question4,answer4,question5,answer5,question6,answer6);
      
        
      } catch (err) {
        console.log(err.message);
      }
      
    }
  }

    

  useEffect(()  => {

getForms(9);
 getQuestions(9);

    }, []);

  

    const validateInputs = ()  =>  {
      const error = [];
      const blankValues = [];
   
      const group_1 = (document.getElementsByName(questions[0]))
      const group_2 = (document.getElementsByName(questions[1]))
      const group_3 = (document.getElementsByName(questions[2]))
      const group_4 = (document.getElementsByName(questions[3]))
      const group_5 = (document.getElementsByName(questions[4]))
      const group_6 = (document.getElementsByName(questions[5]))
     

     group_1.forEach((entry) => {
 
     
      
           if(entry.checked == true) {
             if(entry.attributes.value.value == "yes"){
           
       
              return true;
             }else {
               if (error.length != 0) {
                 if (
                   !error.includes(
                     document.getElementById(entry.id)
                   )
                 ) {
                   error.push(
                     document.getElementById(entry.id)
                   )
                 }
               } else {
                 error.push(document.getElementById(entry.id))
               }
             }
           }else{
             blankValues.push(document.getElementById(entry.id))
           }
         });
       
         group_2.forEach((en) => {
 
     
       
    
           if(en.checked == true) {
            
             if(en.attributes.value.value == "All of the above."){
            
              return true;
             }else {
               if (error.length != 0) {
                 if (
                   !error.includes(
                     document.getElementById(en.id)
                   )
                 ) {
                   error.push(
                     document.getElementById(en.id)
                   )
                 }
               } else {
                 error.push(document.getElementById(en.id))
               }
             }
           }else{
             blankValues.push(document.getElementById(en.id))
           }
         });
       
     
       
         group_3.forEach((ent) => {
 
     
       
          
           if(ent.checked == true) {
            
             if(ent.attributes.value.value == "All of the above"){
        
              return true;
             }else {
               if (error.length != 0) {
                 if (
                   !error.includes(
                     document.getElementById(ent.id)
                   )
                 ) {
                   error.push(
                     document.getElementById(ent.id)
                   )
                 }
               } else {
                 error.push(document.getElementById(ent.id))
               }
             }
           }else{
         
             blankValues.push(document.getElementById(ent.id))
           }
         });

         group_4.forEach((ent) => {
 
     
       
          
          if(ent.checked == true) {
           
            if(ent.attributes.value.value == "Go into the room"){
       
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(ent.id)
                  )
                ) {
                  error.push(
                    document.getElementById(ent.id)
                  )
                }
              } else {
                error.push(document.getElementById(ent.id))
              }
            }
          }else{
        
            blankValues.push(document.getElementById(ent.id))
          }
        });

        group_5.forEach((ent) => {
 
     
       
          
          if(ent.checked == true) {
           
            if(ent.attributes.value.value == "A red light will be flashing, and an alarm will sound"){
       
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(ent.id)
                  )
                ) {
                  error.push(
                    document.getElementById(ent.id)
                  )
                }
              } else {
                error.push(document.getElementById(ent.id))
              }
            }
          }else{
        
            blankValues.push(document.getElementById(ent.id))
          }
        });

        group_6.forEach((ent) => {
 
     
       
          
          if(ent.checked == true) {
           
            if(ent.attributes.value.value == "Immediately open the door"){
       
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(ent.id)
                  )
                ) {
                  error.push(
                    document.getElementById(ent.id)
                  )
                }
              } else {
                error.push(document.getElementById(ent.id))
              }
            }
          }else{
        
            blankValues.push(document.getElementById(ent.id))
          }
        });


   
 
         if (error.length != 0) {
       
     
           var error_list = "";
           error.forEach((errors) => {
            console.log(errors)
             error_list += errors + "\n";
           });
           const retVal =  window.alert("One or more answers are incorrect "         
           );
           setActive(false);
           return false
          
        
        
            
           
         }else if(blankValues.length > 12){
           const retVal =  window.alert("You must answer all questions");
           setActive(false);
           return false
         }else{
        
           
       
           return true
        
            
           
         }
   }
 
   
 


  return (
    <div className="bodilyfluids_container">
                  <div className={isActive ? 'loader_overlay': 'loader_overlay_hidden'}>
        <PulseLoader color="#ffffff" />
      </div>
      <div className="bodilyfluids_header">
        <h1>BULK CHILLERS</h1>
      </div>
      <div className="bodilyfluids_video-wrapper">
        <ReactPlayer 
        url='https://vimeo.com/742062757' 
        controls='true'
        width="100%"
        height="100%" 
        />
      </div>
      <div className="bodilyfluids_form">
    

        <form id="all_input" onSubmit={form_submit}>
          <table>
          {forms.map((r) => (
            <tr key={r.id}>
              <td className="pnsform" ><label className="pnsformlabel">{r.question_text}</label>
                <div className="pnsfrominput">
                  {
                    r.AllTheAnswers.split( ':' ).map( ( item ) => <> 
                    <input
                      name={r.question_id} 
                      id={r.question_id} 
                      type="radio"  
                      value={item}  

                  
                    />
                    <label className="questionlabel" for={r.question_id}>{ item }</label>  </>) }
                    </div>
                  </td>
                </tr>
              ))} 
                    
          </table>
                      
                
                 
                 
         
        </form>
        <button onClick={form_submit}>Submit</button>
      </div>
      <div className="form_backbutton">
          <button className="form_back" onClick={navigateInduction}>
            <span className="form_text">back</span>
          </button>
      </div>
   
    </div>
  )
}

export default BulkChillers;