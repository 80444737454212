import React, { Fragment, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import '../Induction/BodilyFluids.css';
import {Routes, Route, useNavigate, } from 'react-router-dom';
import moment from "moment";
import { getDefaultNormalizer } from "@testing-library/react";
import  sendEmail2  from '../Sendform/sendHandSform';
import { CSVLink } from "react-csv";
import { FaStoreAltSlash, FaWindows } from 'react-icons/fa';
import PulseLoader from "react-spinners/PulseLoader";

function ProductSpills() {
  const today = moment().format("YYYY-MM-DD");
  const [date, setDate] = useState(today);

  const [forms, setForm] = useState([]);
  const [questions, setQuestions] = useState([]);


  const [form_id, set_form_id] = useState("");
  const [formname, setFormname] = useState("");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const [question1, setQuestion1] = useState("I have watched and understand the details of the Clean-Up of Spills Store Operating Procedure.");
  const [question2, setQuestion2] = useState("I understand all of the Safety Requirements of the Clean-Up of Spills Store Operating Procedure.");
  const [question3, setQuestion3] = useState("How do you dispose of broken glass?");
  const [answer1, setAnswer1] = useState("Yes");
  const [answer2, setAnswer2] = useState("Yes");
  const [answer3, setAnswer3] = useState("All of the above.");
  const [question4, setQuestion4] = useState("When do you need to use the Wet Floor signs?");
  const [answer4, setAnswer4] = useState("During the cleaning and drying period");
  const [question5, setQuestion5] = useState("How do you know what to reload on the trolley before putting it away?");
  const [answer5, setAnswer5] = useState("Check the contents list");


  const navigate = useNavigate();
  const navigateInduction = () => {
    navigate('/induction');
  };

  const getForms = async (id) => {


    var jsonData2 = {};
    try {
      const response2 = await fetch(
        `${localStorage.getItem("url")}/api/forms/${id}`
      );
      jsonData2 = await response2.json();
   
    } catch (err) {
     
      console.log(err.message);
    }

    setForm(jsonData2);
    set_form_id(id)
    setFormname(jsonData2[0].name)
   
    
    

  };

  const getQuestions = async (id) =>{

 
    var jsonData2 = {};
    try {
      const response2 = await fetch(
        `${localStorage.getItem("url")}/api/questions/${id}`
      );
      jsonData2 = await response2.json();
  
    } catch (err) {
     
      console.log(err.message);
    }

    jsonData2.forEach((entry) => {
      if(!questions.includes(entry.question_id)){
   
        questions.push(entry.question_id)
      }
      
    })

  };

    const form_submit = async (e) =>{
      e.preventDefault();
      setActive(true);
      if(validateInputs()== true){
     
        try{
    
          const id = form_id;
          const user_id = localStorage.getItem("user_id")


          const body ={id,user_id,date}
          fetch(
          
            `${localStorage.getItem("url")}/api/completeForm`,
            {
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
   
          sendEmail2(formname,first_name,last_name,question1,answer1,question2,answer2,question3,answer3,question4,answer4,question5,answer5);
        
      } catch (err) {
        console.log(err.message);
      }
      
    }else{
     
   
    }
    
  }

    

    const validateInputs = ()  =>  {
     const error = [];
     const blankValues = [];
  
      const group_1 = (document.getElementsByName(questions[0]))
      const group_2 = (document.getElementsByName(questions[1]))
      const group_3 = (document.getElementsByName(questions[2]))
      const group_4 = (document.getElementsByName(questions[3]))
      const group_5 = (document.getElementsByName(questions[4]))

    
    group_1.forEach((entry) => {

    
     
          if(entry.checked == true) {
            if(entry.attributes.value.value == "yes"){
          
        
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(entry.id)
                  )
                ) {
                  error.push(
                    document.getElementById(entry.id)
                  )
                }
              } else {
                error.push(document.getElementById(entry.id))
              }
            }
          }else{
            blankValues.push(document.getElementById(entry.id))
          }
        });
      
        group_2.forEach((en) => {

    
      
   
          if(en.checked == true) {
           
            if(en.attributes.value.value == "yes"){
           
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(en.id)
                  )
                ) {
                  error.push(
                    document.getElementById(en.id)
                  )
                }
              } else {
                error.push(document.getElementById(en.id))
              }
            }
          }else{
            blankValues.push(document.getElementById(en.id))
          }
        });
      
    
      
        group_3.forEach((ent) => {

    
      
         
          if(ent.checked == true) {
           
            if(ent.attributes.value.value == "All of the above."){
              
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(ent.id)
                  )
                ) {
                  error.push(
                    document.getElementById(ent.id)
                  )
                }
              } else {
                error.push(document.getElementById(ent.id))
              }
            }
          }else{
           
            blankValues.push(document.getElementById(ent.id))
          }
        });

        group_4.forEach((en) => {

    
      
   
          if(en.checked == true) {
           
            if(en.attributes.value.value == "During the cleaning and drying period"){
           
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(en.id)
                  )
                ) {
                  error.push(
                    document.getElementById(en.id)
                  )
                }
              } else {
                error.push(document.getElementById(en.id))
              }
            }
          }else{
            blankValues.push(document.getElementById(en.id))
          }
        });

        group_5.forEach((en) => {

    
      
   
          if(en.checked == true) {
           
            if(en.attributes.value.value == "Check the contents list"){
           
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(en.id)
                  )
                ) {
                  error.push(
                    document.getElementById(en.id)
                  )
                }
              } else {
                error.push(document.getElementById(en.id))
              }
            }
          }else{
            blankValues.push(document.getElementById(en.id))
          }
        });
        



 
       
        if (error.length != 0) {
       
     
          var error_list = "";
          error.forEach((errors) => {
            console.log(errors)
            error_list += errors + "\n";
          });
          const retVal =  window.alert("One or more answers are incorrect "         
          );
          setActive(false);
          return false
         
       
       
           
          
        }else if(blankValues.length > 9){
          const retVal =  window.alert("You must answer all questions");
          setActive(false);
          return false
        }else{
       
          
     
          return true
       
           
          
           
          
        }
  };

     
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
    

  useEffect(()  => {


  getForms(5)
  getQuestions(5);

    }, []);



//    const getCheckedValue = async ( groupName ) => {
    
      
  
//  }


  return (
    <div className="bodilyfluids_container">
      <div className={isActive ? 'loader_overlay': 'loader_overlay_hidden'}>
        <PulseLoader color="#ffffff" />
      </div>
      <div className="bodilyfluids_header">
        <h1>PRODUCT SPILLS</h1>
      </div>
      <div className="bodilyfluids_video-wrapper">
        <ReactPlayer 
        url='https://vimeo.com/722756248'
        controls='true' 
        width="100%"
        height="100%" 
        />
      </div>
      <div className="bodilyfluids_form">
    

        <form id="all_input" onSubmit={form_submit}>
          <table>
          {forms.map((r) => (
            <tr key={r.id}>
              <td className="pnsform" ><label className="pnsformlabel">{r.question_text}</label>  
                    <div className="pnsfrominput">
                      {
                        r.AllTheAnswers.split( ':' ).map( ( item ) => <> 
                        <input
                          name={r.question_id} 
                          id={r.question_id} 
                          type="radio"  
                          value={item}
                        />
                        <label className="questionlabel" for={r.question_id}>{ item }</label>  </>) }
                    </div>
                  </td>
                </tr>
              ))} 
                    
          </table>
                      
                
                 
                 
         
        </form>
        <button onClick={form_submit}>Submit</button>
      </div>
      <div className="form_backbutton">
          <button className="form_back" onClick={navigateInduction}>
            <span className="form_text">back</span>
          </button>
      </div>
   
    </div>
  )
}


export default ProductSpills